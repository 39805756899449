import React, { useState } from "react"

import SEO from "../components/seo"
import RosieImage from '../components/RosieImage'
import Form from '../components/Form'
import HelpWithBackground from "../components/HelpWithBackground"
import SessionDetailsBackground from "../components/SessionDetailsBackground"

import 'normalize.css'
import '../styles/global.css'
import 'fontsource-karla'
import 'fontsource-karla/700.css'

export default function IndexPage() {

  // const [showForm, setShowForm] = useState(false)

  // const openForm = () => {
  //   setShowForm(true)
  // }

  // const closeForm = (event) => {
  //   event.preventDefault()
  //   setShowForm(false)
  // }

  return (
    <>
      <SEO title="Home" />
      
      {/* {showForm ? <Form closeForm={closeForm}/> : ''} */}
      
      <div className='fixed-header'>
        <header>
          <div className='navbar-container'>
            <h1 className='navbar-logo'>
              Rosie Beitel<br /> 
              Psychotherapist,<br />
              MSW, RSW
            </h1>
            <nav className='navbar-menu'>
                <a href='#about-myself'>About Myself</a>
                <a href='#what-i-can-help-you-with'>I Can Help You With</a>
                <a href='#my-approach'>My Approach</a>
                <a href='#session-details'>Session Details</a>
            </nav>
            {/* <div className='navbar-button'>
              <button onClick={(openForm)}>Set Up Free Consultation</button>
            </div> */}
            {/* <div className='navbar-email'>
              <a href='mailto:rosiebeitel@sympatico.ca'><i class="fas fa-envelope"></i> rosiebeitel@sympatico.ca</a>
            </div> */}
            <div className='navbar-email'>
              <a href='mailto:rosiebeitel@sympatico.ca'>Please email me at:<br />rosiebeitel@sympatico.ca</a>
            </div>
          </div>
        </header>

        <div className='band'>
          <p>For the duration of the COVID-19 pandemic, my sessions will be held virtually or by telephone.</p>
          {/* <p><strong>Reach me at rosie@sympatico.ca</strong></p> */}
        </div>

      </div>
      
      <div className='non-fixed-content'>
        
        <section className='anchor' id='about-myself'>
          <div className='about-myself-image'>
            <RosieImage />
          </div>
          <div className='about-myself-title'>
            <h2>
              <span className='im-rosie-beitel'>
                I'm Rosie Beitel,<br />
              </span>
              a psychotherapist with over 30 years of experience helping individuals and couples.
            </h2>
          </div>
          <div className='about-myself-paragraph'>
            <p>
              I have had the privilege of working with clients from all walks of life who have experienced a wide range of issues. Through my warm and gentle approach towards therapy, I encourage clients to move forward in their lives with a greater sense of confidence, resilience and self-awareness.
            </p>
          </div>
          <div className='about-myself-quote'>
            <p>I believe fluid conversation between a client and a therapist that allows for the building of trust, connection and safety is the foundation of therapy</p>
          </div>
        </section>

        <HelpWithBackground className='anchor' id='what-i-can-help-you-with'>
          <h2>I Can Help You With</h2>
          <div className='what-i-can-help-you-with-container'>
            <ul>
              <li>Anxiety</li>
              <li>Depressed Moods</li>
              <li>Self-esteem</li>
              <li>Grief/Loss</li>
              <li>Trauma</li>
              <li>Relationship Conflicts</li>
              <li>Couple Counselling</li>
              <li>Separation/Divorce</li>
              <li>Family</li>
              <li>Life Transitions</li>
              <li>Retirement</li>
              <li>Aging</li>
              <li>Illness</li>
              <li>Gender Identity/Sexuality</li>
            </ul>
          </div>
        </HelpWithBackground>

        <section className='anchor' id='my-approach'>
          <h2>My Approach</h2>
          <div className='my-approach-container'>
            <h3>My therapeutic approach is an integration of:</h3>
            <ul>
              <li>Emotionally Focused Therapy</li>
              <li>Attachment Theory</li>
              <li>Psychodynamic Psychotherapy</li>
              <li>Cognitive Behavioural Therapy</li>
              <li>Solution Focused Brief Therapy</li>
              <li>Mindfulness</li>
            </ul>
          </div>
          <div className='my-approach-length-container'>
            <div>
              <h3>Depending on your goals, our focus might be brief or longer term:</h3>
              <h4 className='my-approach-title'>Brief Therapy</h4>
              <p className='my-approach-subtitle'>Focus on problem solving related to a particular crisis or situation</p>
              <p>Clients may be requesting therapy as a response to a particular crisis or situation such as grief/loss, trauma, relationship breakup, job loss and retirement. Generally, they are seeking symptom relief as well as concrete strategies to address these issues in a proactive and direct way. We tend to focus on a specific problem or a crisis, to work towards developing positive and tangible coping strategies that can be successfully applied to day-to-day life. Brief therapy can also be insight-oriented offering clients a more expansive understanding of some of the underlying issues related to their particular situation.</p>
            </div>
            <div>
              <h4 className='my-approach-title'>Long-Term Psychotherapy</h4>
              <p className='my-approach-subtitle'>Focus on identification and understanding of long-standing patterns</p>
              <p>This is an opportunity to gain greater insight and awareness to long-standing issues that might be interfering with one’s day to day life experiences. Often there is a repetition of patterns in interpersonal relationships, intimacy, social functioning, thoughts, feelings and behaviours that is preventing a person from leading a more satisfying and meaningful life. The goal of psychotherapy is to help clients feel more at peace with themselves, to experience improved relationships as well as an overall reduction of personal stress/anxiety and depressed moods.</p>
            </div>
          </div>
        </section>

        <SessionDetailsBackground id='session-details'>
          <h2>Session Details</h2>
          <div className='session-details-container-flex'>
            <div className='session-details-container'>
              <p>To determine if we are a good fit, I offer a free 20-minute telephone consultation.</p>
              <a href='mailto:rosiebeitel@sympatico.ca'>Please email me at:<br />rosiebeitel@sympatico.ca</a>
            </div>
            <div className='session-details-container'>
              <ul>
                <li>My rate is $150 per session.</li>
                <li>I provide a receipt for insurance purposes.</li>
              </ul>
            </div>
          </div>
        </SessionDetailsBackground>

        <footer>
          <p>Website built by <a href='https://sebbeitel.dev' target='_blank'>Sebastien Beitel</a> | Photos by <a href='https://unsplash.com/@aaronburden' target='_blank'>Aaron Burden</a></p>
        </footer>

      </div>
    </>
  )
}
